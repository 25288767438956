import React from "react";
// requires a loader
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import agrotota from "../assets/images/partners/agrotota.png";
import bon_mart from "../assets/images/partners/bon_mart.png";
import ferro from "../assets/images/partners/ferro.png";
import jota_madeiras from "../assets/images/partners/jota_madeiras.png";
import polleto from "../assets/images/partners/polleto.png";
import renascer from "../assets/images/partners/renascer.png";
import stetsom from "../assets/images/partners/stetsom.png";

import "./styles.css";

const Partners = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 7,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 2460,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1900,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="partners-container">
        <p className="partners-title">Parceiros</p>
        <div className="partener-carousel">
          <Slider {...settings}>
            <div className="cr-item">
              <img src={agrotota} alt="Parceiro Agrotota" />
            </div>
            <div className="cr-item">
              <img src={bon_mart} alt="Parceiro Frigorífico Bon-Mart" />
            </div>
            <div className="cr-item">
              <img src={ferro} alt="Parceiro Boutique de Carnes" />
            </div>
            <div className="cr-item">
              <img src={jota_madeiras} alt="Parceiro Jota Madeiras" />
            </div>
            <div className="cr-item">
              <img src={polleto} alt="Parceiro Alimentos Polleto" />
            </div>
            <div className="cr-item">
              <img src={renascer} alt="Parceiro Auto Posto Renascer" />
            </div>
            <div className="cr-item">
              <img src={stetsom} alt="Parceiro Stetsom" />
            </div>
          </Slider>
        </div>
      </div>
    </>
  );
};

export default Partners;
