import Auction from "../Auction";
import logo from "../assets/images/logo3.svg";
import "./styles.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useEffect, useState } from "react";
import axios from "axios";

interface AuctionProps {
  link: string;
  name: string;
  date: string;
  image: string;
}

const S2 = () => {
  const [auctions, setAuctions] = useState<AuctionProps[]>([]);
  const [live, setLive] = useState("");

  useEffect(() => {
    axios.get("/api/v1/auction.php").then((value) => {
      setAuctions(value.data);
    });

    axios.get("/api/v1/video.php").then((value) => {
      setLive(value.data[0].url);
    });
  }, []);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  function utf8_decode ( str_data: string ) {  
      // Converts a UTF-8 encoded string to ISO-8859-1    
      //   
      // version: 810.1317  
      // discuss at: http://phpjs.org/functions/utf8_decode  
      // +   original by: Webtoolkit.info (http://www.webtoolkit.info/)  
      // +      input by: Aman Gupta  
      // +   improved by: Kevin van Zonneveld (http://kevin.vanzonneveld.net)  
      // +   improved by: Norman "zEh" Fuchs  
      // +   bugfixed by: hitwork  
      // +   bugfixed by: Onno Marsman  
      // *     example 1: utf8_decode('Kevin van Zonneveld');  
      // *     returns 1: 'Kevin van Zonneveld'  
      let tmp_arr = []
      let i = 0;  
      let ac = 0
      let c1 = 0
      let c2 = 0;
      let c3 = 0; 
    
      str_data += '';  
    
      while ( i < str_data.length ) {  
          c1 = str_data.charCodeAt(i);  
          if (c1 < 128) {  
              tmp_arr[ac++] = String.fromCharCode(c1);  
              i++;  
          } else if ((c1 > 191) && (c1 < 224)) {  
              c2 = str_data.charCodeAt(i+1);  
              tmp_arr[ac++] = String.fromCharCode(((c1 & 31) << 6) | (c2 & 63));  
              i += 2;  
          } else {  
              c2 = str_data.charCodeAt(i+1);  
              c3 = str_data.charCodeAt(i+2);  
              tmp_arr[ac++] = String.fromCharCode(((c1 & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));  
              i += 3;  
          }  
      }  
    
      return tmp_arr.join('');  
  }  
    

  return (
    <>
      <div className="s2-container">
        <div className="s2-nav" id="nextAuctions">
          <span>PRÓXIMOS LEILÕES</span>
        </div>
        <span id="s2-swap-info">
          ARRASTE PARA O LADO PARA VER OS PRÓXIMOS EVENTOS
        </span>
        <Carousel
          swipeable={true}
          draggable={true}
          showDots={false}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          infinite={false}
          autoPlaySpeed={1000}
          keyBoardControl={true}
          customTransition="all .75"
          transitionDuration={500}
          containerClass="carousel-container"
          additionalTransfrom={32}
          centerMode={true}
          removeArrowOnDeviceType={["tablet", "mobile"]}
          itemClass="carousel-item-padding-40-px"
        >
          {auctions.map((item, index) => {
            return (
              <Auction
                url={item.link}
                image={`sistema/images/${item.image}`}
                title={utf8_decode(item.name).toUpperCase()}
                date={item.date}
              />
            );
          })}
        </Carousel>
        {live !== '' ? <div className="s2-logo">
          <img src={logo} alt="Leilões JSMaia" />
          <div className="s2-live" id="live">AO VIVO</div>
          <div className="s2-video">
            <iframe
              title="live"
              src={`${live}?autoplay=1`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe> 
          </div>
          <div className="s2-video-info">
            Não havendo transmissão ao vivo, o último leilão é exibido
            automaticamente.
          </div>
        </div>:<div className="s2-logo"></div>}
      </div>
    </>
  );
};

export default S2;
