import './styles.css';

interface CB{
    title: string,
    content: string,
    destination: string,
    icon: string,
    iconAlt: string
}

const ContactButton = (props: CB) => {
    return (
        <>
            <div className="contact-btn-container">
                <span className="cb-title">{props.title}</span>
                <a className="cb-content" href={props.destination}><img src={props.icon} alt={props.iconAlt} /><span>{props.content}</span></a>
            </div>
        </>
    );
}

export default ContactButton;