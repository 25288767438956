import './styles.css';
interface AuctionProps {
    url: string,
    title: string,
    date: string,
    image: string
}

const Auction = (props: AuctionProps) => {
    const months = ["JAN", "FEV", "MAR", "ABR", "MAI", "JUN", "JUL", "AGO", "SET", "OUT", "NOV", "DEZ"];
    const month = Number.parseInt(props.date.substring(5, 7)) - 1;
    return (
        <div className="auction-container">
            <div className="auction-img">
                <img src={props.image} alt="Imagem do leilão"/>
            </div>
            <div className="auction-bottom">
                <span className="auction-title">
                    {props.title}
                </span>
                <span className="auction-date">
                    {`${props.date.substring(8, 11)} | ${months[month]} | ${props.date.substring(0, 4)}`}
                </span>
            </div>
            <div className="auction-button">
                <a target="_blank" rel="noreferrer" href={props.url}>CONFIRMAR PRESENÇA</a>
            </div>
        </div>
    );
}

export default Auction;