import React, { useState } from "react";
import Popup from "reactjs-popup";
import "./App.css";
import Contact from "./Contact";
import Header from "./Header";
import Partners from "./Partners";
import S1 from "./S1";
import S2 from "./S2";
import S3 from "./S3";
import S4 from "./S4";

function App() {
  const [open, setOpen] = useState(true);
  const closeModal = () => setOpen(false);

  return (
    <div className="App">
      <Popup open={open} closeOnDocumentClick onClose={closeModal}>
        <div className="modal">
          <a className="close" onClick={closeModal}>
            &times;
          </a>
          <iframe
            src="https://www.youtube.com/embed/8Y4aU-QYnw8"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </Popup>
      <Header />
      <S1 />
      <S2 />
      <S3 />
      <S4 />
      <Partners />
      <Contact />
    </div>
  );
}

export default App;
