import logo2 from '../assets/images/logo2.svg';
import './styles.css';
const S1 = () => {
    return (
        <>
            <div className="s1-container">
                <div className="s1-image-container">
                    <div id="s1-image">
                        <span>
                            O LEILÃO DE PROMISSÃO
                        </span>
                    </div>
                </div>
                <div className="s1-logo">
                    <img src={logo2} alt="Logo Leilões JSMaia"/>
                </div>
            </div>
        </>
    )
}

export default S1;