import './styles.css';
import whats from '../assets/images/zap_red.svg';
import logo2 from '../assets/images/logo2.svg';
import { useEffect, useState } from 'react';
import axios from 'axios';
// @ts-ignore
import renderHTML from 'react-render-html';
import DOMPurify from 'dompurify';
const S4 = () => {
    const [sell, setSell] = useState('');
    const [buy, setBuy] = useState('');
    const [toShow, setToShow] = useState(0);

    useEffect(() => {
        axios.get('/api/v1/sell.php').then(value => {
            let pureHtml = DOMPurify.sanitize(value.data[0].text, {USE_PROFILES: {html: true}});
            setSell(pureHtml);
        });

        axios.get('/api/v1/buy.php').then(value => {
            let pureHtml = DOMPurify.sanitize(value.data[0].text, {USE_PROFILES: {html: true}});
            setBuy(pureHtml);
        });
    }, []);

    return (
        <>
            <div className="s4-container">
                <a id="s4-zap" rel="noreferrer" target="_blank" href="https://api.whatsapp.com/send?phone=5514997227088&text=Oi!">
                    <div className="s4-nav">
                        <span><img src={whats} alt="WhatsApp"/>QUERO FALAR NO WHATSAPP</span>
                    </div>
                </a>
                <div className="s4-header">
                    <div className="s4-text">
                        <p>
                            Mais que um leilão
                        </p>
                        <p>uma parceria para a vida. </p>
                    </div >
                </div>
                <div className="s4-logo">
                    <img src={logo2} alt="Logo Leilões JSMaia"/>
                </div>
                <div className="s4-history" id="bs">
                    <p>
                        Para saber mais<br/>
                        escolha uma opção:
                    </p>
                    <div className="s4-buttons">
                        <div onClick={() => setToShow(0)} className="s4-btn">
                            QUERO<br/>COMPRAR
                        </div>
                        <div onClick={() => setToShow(1)} className="s4-btn">
                            QUERO<br/>VENDER
                        </div>
                    </div>
                    <div className="s4-history-text">
                        <div>
                        {toShow === 0 ? renderHTML(buy) : renderHTML(sell)}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );  
}

export default S4;