import React from 'react';
import ContactButton from '../ContactButton';
import whatsApp from '../assets/images/zap_white.svg';
import email from '../assets/images/email.svg';
import telehpone from '../assets/images/telephone.svg';
import logo4 from '../assets/images/logo4.svg';
import rapha from '../assets/images/rapha.svg';

import './styles.css';

const Contact = () => {
    return (
        <>
            <div className="contact-container">
                <p className="contact-title" id="social">Contato</p>
                <div className="contact-contato">
                    <ContactButton title="FALAR NO WHATSAPP" icon={whatsApp} iconAlt="WhatsApp" content="+55 14 99722-7088" destination="https://api.whatsapp.com/send?phone=5514997227088&text=Oi!" />
                    <ContactButton title="LIGAR PARA MAIS INFORMAÇÕES" icon={telehpone} iconAlt="Telefone" content="+55 14 99722-7088" destination='callto:14997227088' />
                    <ContactButton title="ENVIAR E-MAIL" icon={email} content="contato@jsmaialeiloes.com" iconAlt="Email" destination="mailto:contato@jsmaialeiloes.com" />
                </div>                       
                <div className="contact-map">
                    <div className="footer-container">
                        <div className="fc-map">
                            <div className="cm-title">
                                <p>COMO CHEGAR</p>                        
                            </div>
                            <div className="mapa">
                                <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2203.0744123503905!2d-49.87149322805412!3d-21.568697997473585!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMjHCsDM0JzA3LjMiUyA0OcKwNTInMTQuMSJX!5e1!3m2!1spt-BR!2sbr!4v1613143327937!5m2!1spt-BR!2sbr" frameBorder="0" aria-hidden="false" tabIndex={0}></iframe>
                            </div>
                            <div className="cm-address">
                                <p>
                                Via De Acesso Dr. Shuei Uetsuka, S/N, Gonzaga<br className="hide-on-desk"/>
                                Chacara Estancia Asa Branca, Km 3,1<br/>
                                Promissão - São Paulo <br className="hide-on-desk"/>
                                CEP 16370-000<br/>
                                </p>
                            </div>
                        </div>
                        <img src={logo4} alt="Logo JS Maia Leilões" />
                    </div>                   
                </div>                                   
                <div className="contact-rights">
                    <img src={logo4} alt="Logo JS Maia Leilões" />
                    <p>
                        JS MAIA LEILÕES <br/>
                        todos os direitos reservados
                    </p>
                    <div className="cr-madeby">
                        <p>CRIADO POR</p>
                        <a target="_blank" rel="noreferrer" href="https://raphael.art.br"><img src={rapha} alt="Criado por Raphael Art" /></a>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Contact;