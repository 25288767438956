import React, { useState } from 'react';
import logo from '../assets/images/logo.svg';
import menu from '../assets/images/menu.svg';
import Menu from '../Menu';
import './styles.css';

const Header = () => {
    const [show, setShow] = useState(false);

    return (
        <div className="header-container">
            <img style={{zIndex: 3}} id="logo" src={logo} alt="Logo Leilões JSMaia" />
            <img style={{cursor: 'pointer'}} onClick={() => setShow(!show)} id="menu" src={menu} alt="Menu" />            
            {show ? <Menu update={setShow}/> : null}
        </div>
    )
}

export default Header;