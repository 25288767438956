import zap from '../assets/images/zap_red_2.svg';
import ig from '../assets/images/ig.svg';
import fb from '../assets/images/fb.svg';
import mail from '../assets/images/mail.svg';
import close from '../assets/images/close.png';

import './styles.css';

interface Props {
    update: Function
}

const Menu = (props: Props) => {
    const closeTextStyle = {
        letterSpacing: 5,
        color: '#AC0009',
        fontFamily: 'Roboto, serif'
    }
    return (
        <div className="menu-container">
            <ul className="mc-items">
                <li onClick={() => {
                    props.update(false);
                }}>
                    <a href="#nextAuctions">PRÓXIMOS EVENTOS</a>
                </li>
                <li onClick={() => {
                    props.update(false);
                }}>
                    <a href="#live">LEILÕES AO VIVO</a>
                </li>
                <li onClick={() => {
                    props.update(false);
                }}>
                    <a href="#history">NOSSA HISTÓRIA</a>
                </li>
                <li onClick={() => {
                    props.update(false);
                }}>
                    <a href="#bs">COMPRAR & VENDER</a>
                </li>
                <li onClick={() => {
                    props.update(false);
                }}>
                    <a href="#social">REDES SOCIAIS</a>
                </li>
                <li onClick={() => {
                    props.update(false);
                }}>
                    <a href="#social">CONTATO</a>
                </li>                
            </ul>
            <div className="mc-social">
                <a rel="noreferrer" target="_blank" href="https://api.whatsapp.com/send?phone=5514997227088&text=Oi!">
                    <img src={zap} alt="WhatsApp" />
                </a>
                <a rel="noreferrer" target="_blank" href="https://fb.com/jsmaialeiloes">
                    <img src={fb} alt="Facebook" />
                </a>
                <a rel="noreferrer" target="_blank" href="https://instagram.com/jsmaialeiloes">
                    <img src={ig} alt="Instagram" />
                </a>                
                <a rel="noreferrer" target="_blank" href="mailto:contato@jsmaialeiloes.com">
                    <img src={mail} alt="Email" />
                </a>
            </div>
            <div className="mc-close">                
                <p style={closeTextStyle}>FECHAR MENU</p>          
                <img style={{cursor: 'pointer'}} onClick={() => {
                    props.update(false);
                }} src={close} alt="fechar"/>
            </div>
        </div>
    )
}

export default Menu;