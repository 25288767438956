import './styles.css';
import logo2 from '../assets/images/logo2.svg';
import { useEffect, useState } from 'react';
import axios from 'axios';
import DOMPurify from 'dompurify';
// @ts-ignore
import renderHTML from 'react-render-html';


const S3 = () => {
    const [history, setHistory] = useState('');
    
    useEffect(() => {
        axios.get('/api/v1/history.php').then(value => {
            let pureHtml = DOMPurify.sanitize(value.data[0].text, {USE_PROFILES: {html: true}});
            setHistory(pureHtml);
        });
    }, []);

    return (
        <>
            <div className="s3-container">
                <div className="s3-header">
                    <div className="s3-text">
                        <p>
                            Conectando pessoas,
                        </p>
                        <p>fechando parcerias, </p>
                        <p>prosperando junto.</p>
                    </div >
                </div>
                <div className="s3-logo" id="history">
                    <img src={logo2} alt="Logo Leilões JSMaia"/>
                </div>
                <div className="s3-history">
                    <p>
                        Nossa<br/>História
                    </p>
                    <div className="s3-history-text">
                        <div>
                        {renderHTML(history)}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default S3;